<template>
    <div>

        <h1> 分成配置 </h1>

        <el-input
            type="textarea"
            :rows="11"
            placeholder="请输入内容"
            v-model="text">
        </el-input>

        <el-button type="primary" @click="okClick">提交</el-button>

    </div>
</template>
  
  
<script>
  // 引入组件及方法
  
  export default {
    components: {  },
    data() {
      return {
        text:""
      };
    },
    mounted(){

      this.$https('GET_COMMISSION_CONFIG', {}).then(res => {
        console.log("分成配置", res)
        this.text = JSON.stringify( res.data.list )


      })



    },
    methods: {
        okClick(){
            
            this.$https("SET_COMMISSION_CONFIG", {list:JSON.parse(this.text)}).then(res => {
                this.$message.success('提交成功')
            })
        }
    },
  };
</script>
  
<style></style>
  